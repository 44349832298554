import { trackEvent } from '@do/seashell';
import Router from 'next/router';

export interface HitProps {
  objectId: string;
  queryId: string;
  position: number;
}

interface Props {
  safeName: string;
  appId: string;
  appType: string;
  hit?: HitProps;
}

export default function onSearchSelect({
  safeName,
  appId,
  appType,
  hit
}: Props) {
  if (safeName) {
    trackEvent('Search Click', {
      vendor_name: safeName,
      marketplace_app_id: appId
    });

    let path;

    if (appType === 'saas' || appType === 'sls') {
      path = '/add-ons';
    } else {
      path = '/apps';
    }

    let queryString = '';
    if (hit) {
      queryString = `?objectId=${hit.objectId}&queryId=${hit.queryId}&position=${hit.position}`;
    }

    Router.push(`${path}/[safeName]${queryString}`, `${path}/${safeName}`);
  }
}
